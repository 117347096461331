.kb-item-detail-nav {
    background-color: white !important;
    text-align: center !important;
    font-family: "Lato" !important;
    box-shadow: 0px 1px 5px darkgrey !important;
    width: 100% !important;
    z-index: 100 !important;
    left: 0 !important;
    top: 0 !important;
}
.kb-item-detail-nav-brand {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
}
.kb-detail-description{
    font-family: Lato !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #A9A6A6;
}
.modal-body{
    margin-left: 5%;
    margin-right: 5%;
}
.kb-item-detail-btn {
    /* background-color: #7ac1d1 !important; */
    margin-top: 20px !important;
    font-family: "Lato" !important;
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
    border: none !important;
    font-size: 15px !important;
    width: 100% !important;
    border-radius: 50px !important;
    padding: 10px 16px !important;
}

.kb-item-detail-cancel-btn {
    background-color: #7ac1d1 !important;
    font-family: "Lato" !important;
    display: block !important;
    border: none !important;
    font-size: 15px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px !important;
    width: 100% !important;
    height: 40px !important;
    position: absolute !important;
    margin-top: 40px !important;
    left: 0 !important;
    bottom: 0 !important;
    /* border-radius: 50px !important; */
}
.kb-item-detail-card {
    margin-top: 40px !important;
    box-shadow: 1px 1px 5px darkgrey !important;
    width: 90% !important;
    height: 60% !important;
}
.kb-item-detail-form {
    background: none !important;
    color: #495057 !important;
    font-family: "Lato" !important;
    transition: none !important;
    border: none !important;
    border-bottom: 2px solid !important;
}

.hc-button-group{
    position: relative;
    margin-top : 1%;
    margin-right : 0;
    display: flex;
    justify-items: self-start;
    height : 3vh;
}

.hc-button-group .widebtn{
    height : 130%;
    width : 90%;
    color : black;
    background-color: white;
    font-size: 11px !important;
    font-weight: bold !important;
    margin-top: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid white;
}

.hc-button-group .smallbtn{
    height : 130%;
    width : 40%;
    color : black;
    /* background-color: #7ac1d1; */
    font-size: 11px !important;
    font-weight: bold !important;
    margin-top: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

