.kb-timeline-with-tail {
    /* border: 1px solid black; */
}

.kb-timeline-flex {
    display: flex;
    align-items: center;
}

.kb-timeline-text {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
    margin-left: 1rem;
}

.kb-timeline-circle {
    width: 25px;
    height: 25px;
    background: #6bb9cd;
    border-radius: 50%;
}

.kb-timeline-line {
    background: #6bb9cd;
    width: 1px;
    height: 28px;
    margin-left: 12px;
}