body {
  height: 100% !important;
  margin: 0;
  padding: 0px;
  font-family: 'Lato', sans-serif;
  /* background-color: black !important; */
}

@font-face {
  font-family: 'Lato', sans-serif;
  src : url('https://fonts.googleapis.com/css?family=Lato:400,700,900,400italic,700italic,300,300italic&display=swap');
  font-display: swap;
}
