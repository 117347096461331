@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.kb-receipt-page {
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid black; */
  width: 100%;
  padding: 1.5rem 0;
  color: black;
}

.kb-receipt-header {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-header-text {
  font-size: 1.3rem;
  font-weight: 500;
  flex: 1;
  text-align: center;
  /* border: 1px solid black; */
  margin-bottom: 0;
}

.kb-receipt-main {
  /* border: 1px solid black; */
}

.kb-receipt-top {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.kb-receipt-top-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.kb-receipt-top-right {
  display: flex;
  align-items: center;
}

.kb-receipt-top-right-btn-left, .kb-receipt-top-right-btn-right {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: transparent;
}

.kb-receipt-top-right-btn-left {
  margin-right: 1rem;
}
.kb-receipt-top-left {
  /* border: 1px solid black; */
  width: 50%;
}

.kb-receipt-top-head,
.kb-receipt-top-sub {
  /* border: 1px solid red; */
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.kb-receipt-top-head {
  font-size: 15px;
}

.kb-receipt-top-sub {
  font-size: 10px;
  opacity: 0.5;
}

.kb-receipt-top-details {
  /* border: 1px solid black; */
  padding: 1rem 1rem 0 1rem;
}

.kb-receipt-top-details p {
  /* margin: 0; */
  font-weight: 500;
  font-size: 12px;
}

.kb-receipt-top-details-text {
  margin: 0;
}

.kb-receipt-top-details-heading {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.kb-receipt-line {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.kb-receipt-option {
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-footer {
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-footer-btn {
  width: 100%;
  display: block;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 15px 30px;
  border: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  font-size: 18px;
}

.kb-receipt-footer-btn:focus {
  outline: transparent;
}
