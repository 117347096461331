.kb-customization-choice-group {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
}

.kb-customization-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.kb-customization-btn {
  width: 40px;
  height: 40px;
  border: transparent;
  background-color: white;
  color: black;
  border-radius: 5px;
}

.kb-customization-btn:focus {
  outline: transparent;
}
.kb-customization-btn:active {
  outline: 1px solid #999;
}

.kb-customization-qty {
  margin: 0 2rem;
}

.kb-customization-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2rem;
}

.kb-customization-total-container p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.kb-customization-bottom-add,
.kb-customization-bottom-cancel {
  display: block;
  width: 100%;
  border: transparent;
  padding: 0.75rem 0;
  font-weight: 600;
  font-size: 18px;
}

.kb-customization-bottom-cancel {
  background-color: #ccc;
}

.kb-customization-bottom-add {
  color: white;
}
