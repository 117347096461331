.product-card {
    width: 40%;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    height: 300% !important;
    position: relative;
    color: white;
}

.product-card-img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.product-card-footer {
    border-radius: 60px;
    color: #7ac1d1;
    width: 100%;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: #ffff;
    z-index: 100px;
}

.card-overlay-top {
    color: white;
}

.card-overlay-bottom {
    color: white;
    position: absolute;
    top: 70%;
}

.product-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 50%;
}

.product-title-overlay {
    background-image: linear-gradient(rgba(2, 44, 36, 0.363) 20px, rgba(252, 252, 252, 0.068) 60px, rgba(255, 255, 255, 0.048));
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
    position: absolute;
    width: 100%;
    font-size: 150%;
}

.card-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #ffff;
    color: #7ac1d1;
    font-size: 150%;
}

.card-btn:hover,
.card-btn:focus {
    color: #ffff;
    background-color: #7ac1d1;
}