.kb-receipt-takeaway-container p {
    margin-bottom: 0;
}

.kb-receipt-takeaway-top-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kb-receipt-takeaway-text-left {
    font-weight: 500;
    font-size: 13px;
    color: rgba(0,0,0,0.6);
    text-transform: uppercase;
}

.kb-receipt-takeaway-text-right {
    font-weight: 600;
    font-size: 15px;
    color: #6bb9cd;
}

.kb-receipt-takeaway-timeline {
    margin-top: 1.5rem;
}

.kb-receipt-takeaway-status {
    font-weight: 500;
    font-size: 13px;
    color: white;
    background-color: #6bb9cd;
    padding: .25rem .75rem;
    border-radius: 20px;
    text-transform: capitalize;
}