/* .type {
    width: 10px !important;
    height: 10px !important;
} */

.type-inner {
    padding: 2px !important;
    margin: 1px;
    height: 5px;
    position: absolute;
}