.modal {
    text-align: center;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    /* -webkit-backdrop-filter: blur( 10.0px ); */
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
