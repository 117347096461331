.kb-cart-navbar {
  background-color: white;
  font-family: Lato;
  box-shadow: 0px 1px 5px darkgrey;
  width: 100%;
  position: fixed;
  z-index: 99 !important;
  left: auto;
  top: 0;
  height: 60px;
}

.kb-cart-navbar-brand {
  font-size: 15px;
  color: #5a5050;
  text-align: center;
  width: 100%;
  font-family: Lato;
  margin-top: 0;
}

.kb-cart-btn {
  background-color: #f15b4d !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  width: 130px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  text-align: center;
}

.kb-cart-card-item {
  margin-top: 20px;
  box-shadow: 1px 1px 5px darkgrey;
  padding-top: 50px;
}

.kb-cart-card {
  margin-top: 20px;
  box-shadow: 1px 1px 5px darkgrey;
  margin-right: 5px;
  margin-left: 5px;
  font-family: Lato;
  z-index: 100;
}

.kb-cart-title {
  font-family: Lato;
  font-size: 15px;
  color: #6abacd;
  padding-bottom: 10px;
  border-radius: 1px solid;
  border-bottom-color: #b9a6a6;
}

.kb-cart-label {
  display: block;
  font-family: "Lato";
  font-size: 12px;
  color: #544f4f;
}

.kb-cart-confirm-btn {
  background-color: #7ac1d1 !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 120 !important;
  left: 0;
  bottom: 0;
}

.kb-cart-pay-btn {
  font-family: Lato;
  background-color: #7ac1d1 !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 100 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-pay-modal-btn {
  font-family: Lato;
  background-color: #f15b4d !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  width: 50%;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.kb-cart-login-modal-btn {
  font-family: Lato;
  background-color: #f15b4d !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  height: 40px;
}

.kb-cart-cancel-modal-btn {
  background-color: #6abacd;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 40px;
  position: absolute;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-cancel-btn {
  background-color: #f15b4d !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 40px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-placed-tab {
  background-color: #ffffff !important;
  color: #6abacd !important;
  font-family: Lato;
  display: block;
  border: none !important;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
  top: 60px;
}
.kb-cart-placed-tab-active {
  background-color: #6abacd !important;
  color: #ffffff !important;
  font-family: Lato;
  display: block;
  border: none !important;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
  top: 60px;
}

.kb-cart-pending-tab-btn {
  background-color: #6abacd;
  color: #ffffff;
  font-family: Lato;
  display: block;
  border: none !important;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  left: 0;
  bottom: 0;
  top: 60px;
}

.kb-cart-table {
  font-family: "Lato";
  font-size: 15px;
  color: #544f4f;
  margin-top: 60px;
}

.kb-cart-thead {
  color: rgb(106, 186, 205);
}

.kb-cart-tip {
  border: none;
  border-radius: 1px solid;
  border-bottom-color: #b9a6a6;
  font-family: "Lato";
  font-size: 15px;
  color: #544f4f;
  width: 60px;
  text-align: right;
}
