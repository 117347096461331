@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
body {
  height: 100% !important;
  margin: 0;
  padding: 0px;
  font-family: 'Lato', sans-serif;
  /* background-color: black !important; */
}

@font-face {
  font-family: 'Lato', sans-serif;
  src : url('https://fonts.googleapis.com/css?family=Lato:400,700,900,400italic,700italic,300,300italic&display=swap');
  font-display: swap;
}

.modal {
    text-align: center;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    /* -webkit-backdrop-filter: blur( 10.0px ); */
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

html, body {
    padding: 0;
    margin: 0;
  }
  
.header {
    margin: 0;
}
.kb-cart-navbar {
  background-color: white;
  font-family: Lato;
  box-shadow: 0px 1px 5px darkgrey;
  width: 100%;
  position: fixed;
  z-index: 99 !important;
  left: auto;
  top: 0;
  height: 60px;
}

.kb-cart-navbar-brand {
  font-size: 15px;
  color: #5a5050;
  text-align: center;
  width: 100%;
  font-family: Lato;
  margin-top: 0;
}

.kb-cart-btn {
  background-color: #f15b4d !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  width: 130px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  text-align: center;
}

.kb-cart-card-item {
  margin-top: 20px;
  box-shadow: 1px 1px 5px darkgrey;
  padding-top: 50px;
}

.kb-cart-card {
  margin-top: 20px;
  box-shadow: 1px 1px 5px darkgrey;
  margin-right: 5px;
  margin-left: 5px;
  font-family: Lato;
  z-index: 100;
}

.kb-cart-title {
  font-family: Lato;
  font-size: 15px;
  color: #6abacd;
  padding-bottom: 10px;
  border-radius: 1px solid;
  border-bottom-color: #b9a6a6;
}

.kb-cart-label {
  display: block;
  font-family: "Lato";
  font-size: 12px;
  color: #544f4f;
}

.kb-cart-confirm-btn {
  background-color: #7ac1d1 !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 120 !important;
  left: 0;
  bottom: 0;
}

.kb-cart-pay-btn {
  font-family: Lato;
  background-color: #7ac1d1 !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 100 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-pay-modal-btn {
  font-family: Lato;
  background-color: #f15b4d !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  width: 50%;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.kb-cart-login-modal-btn {
  font-family: Lato;
  background-color: #f15b4d !important;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px;
  height: 40px;
}

.kb-cart-cancel-modal-btn {
  background-color: #6abacd;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 40px;
  position: absolute;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-cancel-btn {
  background-color: #f15b4d !important;
  font-family: Lato;
  display: block;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 40px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
}

.kb-cart-placed-tab {
  background-color: #ffffff !important;
  color: #6abacd !important;
  font-family: Lato;
  display: block;
  border: none !important;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
  top: 60px;
}
.kb-cart-placed-tab-active {
  background-color: #6abacd !important;
  color: #ffffff !important;
  font-family: Lato;
  display: block;
  border: none !important;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  right: 0;
  bottom: 0;
  top: 60px;
}

.kb-cart-pending-tab-btn {
  background-color: #6abacd;
  color: #ffffff;
  font-family: Lato;
  display: block;
  border: none !important;
  border: none;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  width: 50%;
  height: 50px;
  position: fixed;
  z-index: 120 !important;
  left: 0;
  bottom: 0;
  top: 60px;
}

.kb-cart-table {
  font-family: "Lato";
  font-size: 15px;
  color: #544f4f;
  margin-top: 60px;
}

.kb-cart-thead {
  color: rgb(106, 186, 205);
}

.kb-cart-tip {
  border: none;
  border-radius: 1px solid;
  border-bottom-color: #b9a6a6;
  font-family: "Lato";
  font-size: 15px;
  color: #544f4f;
  width: 60px;
  text-align: right;
}

.kb-menu-navbar {
    background-color: white;
    font-family: 'Lato' !important;
    box-shadow: 0px 1px 5px darkgrey;
    width: 100% !important;
    position: fixed !important;
    z-index: 99 !important;
    left: auto !important;
    
    top: 0 !important;
    height: 135px
}

.kb-menu-navbar-brand {
    font-size: 15px;
    color: #5a5050 !important;
    text-align: center;
    width: 100%;
    font-family: 'Lato' !important;
}

.kb-menu-btn-danger {
    background-color: #ffffff;
    font-family: 'Lato';
    border: none;
    border-radius: 6px;
    color: #f15b4d;
    padding: 5px;
    width: 40px;
    height: inherit;
}

.kb-menu-view-btn {
    background-color: #f15b4d;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    border-radius: 6px;
    width: 50%;
    height: 40px;
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
}

.best-seller-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-column-gap: 5px;
}

.kb-menu-cancel-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 50%;
    height: 40px;
    position: fixed;
    z-index: 120;
    right: 0;
    bottom: 0
}

.kb-menu-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 48px;
    left: 0;
    border-radius: 20px;
    margin: 5px;
}

.kb-menu-cart-alt-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 48px;
    left: 0;
    border-radius: 20px;
    margin: 5px;
    margin-left: "35%"
}

.kb-menu-btn-green {
    background-color: #5cb85c;
    margin-top: 20px;
    font-family: 'Lato';
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-size: 15px;
    width: 130px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    border-radius: 6px;
    padding: 10px 16px;
}

.kb-menu-card {
    margin-top: 5px;
    box-shadow: "1px 1px 5px darkgrey"
}

.kb-menu-card-title {
    font-family: 'Lato';
    font-size: 15px;
    color: #6abacd;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-bottom-color: #b9a6a6;
}

.kb-menu-label {
    display: block;
    font-family: 'Lato';
    font-size: "15px"
}

.kb-menu-label-light {
    display: block;
    font-family: 'Lato';
    font-size: 15px;
    color: #ffffff;
}

.kb-menu-table {
    font-family: 'Lato';
    font-size: 15px;
    color: #544f4f;
}

.kb-menu-thead {
    color: rgb(106, 186, 205);
}

.kb-menu-tip {
    border: none;
    border-bottom: 1px solid;
    border-bottom-color: #b9a6a6;
    font-family: 'Lato';
    font-size: 15px;
    color: #544f4f;
    width: 60px;
    text-align: right;
}

.kb-menu-tip-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-size: 12px;
    max-width: 60px;
    box-shadow: 1px 3px 5px lightgrey;
}

.kb-menu-bottom {
    width: 40%;
}

.kb-menu-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.kb-menu-list {
    border: 0;
    max-height: 60px;
    font-family: 'Lato';
    font-size: 15px;
    background-color: white;
    margin: 0;
    padding: 5px;
}
.kb-menu-new-btn {
    background-color: #6abacd;
    font-family: "Lato";
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 50px;
    right: 33%;
    border-radius: 20px;
    margin: 5px;
}

.kb-menu-new-menu-label {
    display: block;
    font-family: "Lato";
    font-size: 15px;
    color: #ffffff;
}

.kb-menu-new-list-item {
    border: 0 !important;
    max-height: 60px;
    font-family: "Lato";
    font-size: 13px;
    background-color: white;
    margin: 0;
    padding: 5px;
}

.kb-menu-new-lable-light {
    display: block;
    font-family: "Lato" !important;
    font-size: 15px;
    color: #ffffff;
    background-color: #7ac1d1;
}

.kb-menu-new-menu-btn {
    /* background-color: #DAE5E0 !important; */
    font-family: "Lato" !important;
    display: block;
    border: none !important;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 20%;
    height: 70px;
    position: fixed;
    z-index: 120 !important;
    bottom: 25px !important;
    right: 38%;
    border-radius: 60px !important;
    margin: 5px;
    color: #7ac1d1;
}

.product-card {
    width: 40%;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    height: 300% !important;
    position: relative;
    color: white;
}

.product-card-img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.product-card-footer {
    border-radius: 60px;
    color: #7ac1d1;
    width: 100%;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: #ffff;
    z-index: 100px;
}

.card-overlay-top {
    color: white;
}

.card-overlay-bottom {
    color: white;
    position: absolute;
    top: 70%;
}

.product-title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: relative;
    top: 50%;
}

.product-title-overlay {
    background-image: linear-gradient(rgba(2, 44, 36, 0.363) 20px, rgba(252, 252, 252, 0.068) 60px, rgba(255, 255, 255, 0.048));
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
    position: absolute;
    width: 100%;
    font-size: 150%;
}

.card-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #ffff;
    color: #7ac1d1;
    font-size: 150%;
}

.card-btn:hover,
.card-btn:focus {
    color: #ffff;
    background-color: #7ac1d1;
}
html, body, #root {
  min-height: 100vh;
}

.kb-btn {
  width: 253px;
  height: 43px;
  /* background-color: #7ac1d1 !important; */
  font-family: Lato;
  border: none !important;
  border-radius: 50px !important;
  color: #ffffff;
}
.kb-btn-2 {
  width: 253px;
  height: 43px;
  background-color: rgb(240, 80, 69) !important;
  font-family: Lato;
  border: none !important;
  border-radius: 50px !important;
  color: #ffffff;
}
.kb-lable {
  font-family: Lato;
  font-size: 20px;
  color: #636466;
}
.kb-category-label {
  background-color: white;
  border: none !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 10px;
}
.kb-navbar {
  background-color: white;
  text-align: center;
  font-family: Lato;
  box-shadow: 0px 1px 5px darkgrey;
  width: 100%;
  position: fixed !important;
  z-index: 100 !important;
  left: 0;
  bottom: 0;
}

/* ======== Qr Code Scanner ======= */
.kb-scanner-navbar {
  background-color: white;
  font-family: Lato;
  width: 100%;
  z-index: 100;
  left: "auto";
  top: 0;
  height: 190px;
}

.kb-scanner-navbar-brand {
  font-size: 50px !important;
  color: #5a5050;
  text-align: center;
  margin-top: 15px;
  width: 100%;
  font-family: Lato;
}

.kb-scanner-heading {
  font-family: Lato;
  text-align: center;
  color: #000000;
  font-size: 26px !important;
}

/* ========== Scanner Landing ========= */
.kb-scanner-card {
  margin-top: 20px !important;
  /* box-shadow: 1px 1px 5px darkgrey; */
  /* margin-left: 2em;
    margin-right: 2em; */
}

.kb-scanner-btn1 {
  background-color: #6abacd !important;
  font-family: Lato !important;
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
  border: none !important;
  font-size: 15px !important;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px !important;
  padding: 10px 16px !important;
}

.kb-scanner-btn2 {
  background-color: #f15b4d !important;
  font-family: Lato !important;
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
  border: none !important;
  font-size: 15px !important;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px !important;
  padding: 10px 16px !important;
}

/* .kb-label {
    display: block;
    font-family: Lato;
    font-size: 15px;
    color: #544f4f;
} */

/* ====== Menu Item =========== */
.kb-menu-label {
  display: block;
  font-family: Lato;
  font-size: 15px;
  color: "#544f4f";
}

.kb-menu-label2 {
  display: block;
  font-family: Lato;
  font-size: 15px;
  color: "#544f4f";
}

.kb-menu-add-btn {
  background-color: "#6abacd" !important;
  font-family: Lato !important;
  border: none !important;
  font-size: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px !important;
  border-radius: 6px !important;
  height: 40px !important;
  margin-top: -8px !important;
}

.card-cart-btn {
  border: none;
  color: #7ac1d1;
  font-family: Lato;
  padding: 1%;
  border-width: 1px;
  border-style: solid;
  border-color: gray !important;
  padding: 4%;
}
.card-cart-btn:focus {
  color: #6abacd !important;
  background-color: #e4faff !important;
  border: none !important;
}

.card-btn-l {
  background-color: #ffffff;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.card-btn-r {
  background-color: #ffffff;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
.footer-svg {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

/* _____ Filter ____ */
.kb-filter {
  display: -webkit-flex;
  display: flex;
  margin-top: 160px;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-left: 13px;
  color: #a9a6a6;
}
.kb-filter h6 {
  margin-bottom: 0px;
  margin-top: 5px;
}
.special_price {
  text-decoration: line-through !important;
  color: gray;
}
.pos_wrapper {
  display: -webkit-flex;
  display: flex;
  height: 40px;
  /* align-content: space-around; */
  overflow-x: scroll;
  width: 100%;
}
.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* border: solid; */
  border-width: 1.02px;
  margin: auto;
}

.scrollmenu span {
  display: inline-block;
  /* color: white; */
  text-align: center;
  padding: 14px;
  text-decoration: none;
  /* border: solid;
  border-left-width: 10px; */
}

.scrollmenu span {
  border-right: thin;
  border-style: solid;
  /* border: 1px; */
  border-width: thin;
}
.scrollmenu .active {
  background-color: rgb(238, 255, 255);
  /* border: solid; */
}
.text-shadow {
  text-shadow: 1px 1px 11px rgba(140, 150, 150, 1);
}

/* *{
    font-family: 'Montserrat' !important;
} */

input[type='radio'] {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 1px gray;
    margin-right:15px
  }
  
  input[type='radio']:checked {
    box-shadow: 0 0 0 1px #3d9dac;
  }
  
  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  
  
  input[type='radio']:checked:before {
    background: #3d9dac;
  }
  
  input[type="radio"]:checked + p {
    font-weight:bold;
  } 

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 12px;
    width: 12px;
    border: 2px solid gray;
    margin-right:15px;
  }
  
  input[type="checkbox"]:checked {
    background: #3d9dac;
  } 

  input[type="checkbox"]:checked + p {
    font-weight:bold;
  } 

  #position-relative.form-group .label{
      display:none !important;
  }

  .form-control:focus{
    -webkit-box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.Select-clear {
  display:none !important;
}

.kb-item-detail-nav {
    background-color: white !important;
    text-align: center !important;
    font-family: "Lato" !important;
    box-shadow: 0px 1px 5px darkgrey !important;
    width: 100% !important;
    z-index: 100 !important;
    left: 0 !important;
    top: 0 !important;
}
.kb-item-detail-nav-brand {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
}
.kb-detail-description{
    font-family: Lato !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #A9A6A6;
}
.modal-body{
    margin-left: 5%;
    margin-right: 5%;
}
.kb-item-detail-btn {
    /* background-color: #7ac1d1 !important; */
    margin-top: 20px !important;
    font-family: "Lato" !important;
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
    border: none !important;
    font-size: 15px !important;
    width: 100% !important;
    border-radius: 50px !important;
    padding: 10px 16px !important;
}

.kb-item-detail-cancel-btn {
    background-color: #7ac1d1 !important;
    font-family: "Lato" !important;
    display: block !important;
    border: none !important;
    font-size: 15px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px !important;
    width: 100% !important;
    height: 40px !important;
    position: absolute !important;
    margin-top: 40px !important;
    left: 0 !important;
    bottom: 0 !important;
    /* border-radius: 50px !important; */
}
.kb-item-detail-card {
    margin-top: 40px !important;
    box-shadow: 1px 1px 5px darkgrey !important;
    width: 90% !important;
    height: 60% !important;
}
.kb-item-detail-form {
    background: none !important;
    color: #495057 !important;
    font-family: "Lato" !important;
    transition: none !important;
    border: none !important;
    border-bottom: 2px solid !important;
}

.hc-button-group{
    position: relative;
    margin-top : 1%;
    margin-right : 0;
    display: -webkit-flex;
    display: flex;
    justify-items: self-start;
    height : 3vh;
}

.hc-button-group .widebtn{
    height : 130%;
    width : 90%;
    color : black;
    background-color: white;
    font-size: 11px !important;
    font-weight: bold !important;
    margin-top: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid white;
}

.hc-button-group .smallbtn{
    height : 130%;
    width : 40%;
    color : black;
    /* background-color: #7ac1d1; */
    font-size: 11px !important;
    font-weight: bold !important;
    margin-top: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}


.kb-customization-choice-group {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
}

.kb-customization-btn-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.kb-customization-btn {
  width: 40px;
  height: 40px;
  border: transparent;
  background-color: white;
  color: black;
  border-radius: 5px;
}

.kb-customization-btn:focus {
  outline: transparent;
}
.kb-customization-btn:active {
  outline: 1px solid #999;
}

.kb-customization-qty {
  margin: 0 2rem;
}

.kb-customization-total-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.75rem 2rem;
}

.kb-customization-total-container p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.kb-customization-bottom-add,
.kb-customization-bottom-cancel {
  display: block;
  width: 100%;
  border: transparent;
  padding: 0.75rem 0;
  font-weight: 600;
  font-size: 18px;
}

.kb-customization-bottom-cancel {
  background-color: #ccc;
}

.kb-customization-bottom-add {
  color: white;
}

.kb-choice-field-container {
  font-family: "Poppins", sans-serif;
}

.kb-choice-title-new {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}

.kb-choice-radio-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.kb-choice-radio-container p {
  margin-bottom: 0;
}

.kb-choice-radio-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.kb-choice-radio-price-text {
  margin-left: auto;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.kb-choice-radio-input {
  margin-bottom: 0;
}

.kb-choice-radio-label {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.kb-choice-custom-bold {
  font-weight: 700;
}

.kb-choice-check-container {
  /* border: 1px solid black; */
}

.kb-choice-check-label {
  margin-bottom: 1rem;
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.kb-choice-check-label p {
  margin-bottom: 0;
}

.kb-choice-check-text {
  font-size: 14px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.03rem;
}

.order-main-block p {
    text-align: left;
    margin-bottom: 10px;
}

.order-left-block {
    border: 2px solid #8dd7d6;
    margin: 15px 0;
    padding: 10px;
}

.order-left-block .header {
    font-size: 18px;
    font-weight: bold;
}

.order-left-block .text {
    font-size: 16px;
    padding-left: 10px;
}

.order-center-block {
    margin: 15px 0;
}

.order-center-block thead {
    border: 2px solid #8dd7d6;
    background-color: #8dd7d6;
    color: #fff;
}

.order-center-block thead th {
    padding: 15px 5px;
}

.order-center-block tbody {
    border: 2px solid #8dd7d6;
}

.order-center-block tbody th {
    padding: 15px 5px;
    text-align: center;
}

.order-center-block tbody th.titleTh {
    text-align: left;
}

.order-center-block .order_details {
    margin-bottom: 15px;
}

.order-center-block .order_details .button-collapse {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    cursor: pointer;
    font-size: 18px;
}

.order-center-block .order_details .collapsed {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.order-center-block tbody th .header {
    font-size: 16px;
}

.order-center-block tbody th .description {
    font-size: 10px;
}

.order-center-block tbody th .counter {
    background-color: #8dd7d6;
    margin: 5px;
    height: 15px;
    line-height: 0;
    font-size: 13px;
    outline: none;
    border: 0;
    border-radius: 10px;
}

.order-right-block {
    border: 2px solid #8dd7d6;
    margin: 15px 0;
    padding: 10px;
}

.order-right-block .header {
    font-size: 18px;
    font-weight: bold;
    background-color: #8dd7d6;
    margin: -10px -10px 0 -10px;
    text-align: center;
    padding: 10px;
    color: #fff;
}

.order-right-block button.add-card {
    background-color: #8dd7a0;
    border: 0;
    color: #fff;
    padding: 8px;
    width: -webkit-fill-available;
    margin: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.order-right-block button.pay-btn{
    background-color: #d73d6b;
    border: 0;
    color: #fff;
    padding: 8px;
    width: -webkit-fill-available;
    margin: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.order-right-block .total-header{
    font-size: 16px;
    font-weight: bold;
}

.order-right-block .total-bill{
    font-size: 18px;
    font-weight: bold;
    float: right;
}

.radio {
    background-color: #e7e7e7;
    padding: 8px;
    margin: 15px;
}

.radio label {
    line-height: 20px;
}
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
.kb-menu-item {
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
  grid-gap: 0.5rem;
  border-radius: 5px;
  border: transparent;
}

.kb-menu-item p {
  margin-bottom: 0;
}

.kb-menu-item-left-heading {
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 0;
}

.kb-menu-item-left-text {
  font-weight: 400;
  font-size: 10px;
  margin-top: 0.25rem;
}

.kb-menu-item-left-price {
  font-weight: 700;
  font-size: 12px;
  margin-top: 0.25rem;
}

.kb-menu-item-right-img-container {
  width: 100%;
  height: 70%;
  border-radius: 5px;
}

.kb-menu-item-right-img {
  width: 100%;
    object-fit: contain;
    border-radius: 5px;
    min-width: 100%;
    max-height: 87px;
}

.kb-menu-item-right-btn {
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: white;
  margin: auto;
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  /* border-radius: 70px; */
  color: white;
}

.kb-menu-item-cart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-weight: 500;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  margin: auto;
  margin-top: 0.5rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.kb-menu-item-qty-ctrl {
  color: white;
  border: transparent;
  padding: 0.2rem;
}

/* modal styles */
.kb-detail-modal .modal-content {
  /* background-image: url('../img/img.jpeg') !important; */
  /* background-color: aqua !important; */
  background-color: inherit;
  border: none;
  width: 100%;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}
.kb-detail-modal {
  position: relative;
}
.kb-detail-modal img {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.kb-detail-header {
  color: #ffffff !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  z-index: 10 !important;
  position: absolute !important;
  top: 0px;
}

.kb-detail-top-gradient {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.719) 20px,
    rgba(0, 0, 0, 0.082) 60px
  ) !important;
  padding: 7% !important;
  position: absolute !important;
  top: 0% !important;
  left: 0% !important;
  width: 100% !important;
  z-index: 1 !important;
}

.kb-detail-body {
  top: 10px;
  /* border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important; */
  border: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.kb-detail-body-heading {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  margin-bottom: 5% !important;
}

.kb-detail-body-heading h5 {
  font-size: 110% !important;
}

.kb-detail-body-heading .ant-rate {
  font-size: 110% !important;
}

.kb-details-footer {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background-color: #ffff !important;
  border: none !important;
}

.kb-details-footer .kb-detail-add-btn {
  background-color: #7ac1d1 !important;
  border: none !important;
  /* border-radius: 15px !important; */
  width: 100% !important;
}

.kb-menu-item-eatarian {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  border-radius: 1px;
}

/* .type {
    width: 10px !important;
    height: 10px !important;
} */

.type-inner {
    padding: 2px !important;
    margin: 1px;
    height: 5px;
    position: absolute;
}
.kb-customization-choice-group {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
}

.kb-customization-btn-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.kb-customization-btn {
  width: 40px;
  height: 40px;
  border: transparent;
  background-color: white;
  color: black;
  border-radius: 5px;
}

.kb-customization-btn:focus {
  outline: transparent;
}
.kb-customization-btn:active {
  outline: 1px solid #999;
}

.kb-customization-qty {
  margin: 0 2rem;
}

.kb-customization-total-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.75rem 2rem;
}

.kb-customization-total-container p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.kb-customization-bottom-add,
.kb-customization-bottom-cancel {
  display: block;
  width: 100%;
  border: transparent;
  padding: 0.75rem 0;
  font-weight: 600;
  font-size: 18px;
}

.kb-customization-bottom-cancel {
  background-color: #ccc;
}

.kb-customization-bottom-add {
  color: white;
}

.kb-login-page {
  font-family: "Montserrat", sans-serif;
}

.kb-login-modal {
  font-family: "Montserrat", sans-serif;
}

.kb-login-modal-body {
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  /* margin-top: 10rem; */
  padding-bottom: 0;
  position: relative;
  height: 100%;
}

.kb-login-modal-close {
  /* border: 1px solid black; */
  position: absolute;
  right: -8px;
  top: 4px;
  width: 40px;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.kb-login-modal-close-text {
  margin-bottom: 0;
  font-size: 60px;
}

.modal-content {
  border: transparent !important;
}

.kb-login-page-numbers {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10rem;
}

.kb-login-page-number {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 50px;
  width: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
  border-radius: 50%;
}


.kb-login-page-title {
  font-weight: 600;
  font-size: 28px;
}

.kb-login-page-sub {
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0;
}

.kb-login-page-sub-span {
  font-weight: 600;
}

.kb-login-form-container {
  margin-top: 3rem;
}

.kb-login-form-otp-container {
  margin-top: 1.5rem;
}

.kb-login-form-label {
  font-weight: 500;
  font-size: 12px;
  width: 100%;
}

.kb-login-form-grid {
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  -webkit-align-items: center;
          align-items: center;
  /* display: flex; */
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 4px solid #ddd;
  position: relative;
}

.kb-login-icon {
  position: absolute;
  right: 5px;
  bottom: 2.5px;
}

.kb-login-form-text {
  margin-bottom: 0;
}

.kb-login-form-input {
  width: 100%;
  border: transparent;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: 500;
  /* padding-left: 4.8rem;
  padding-right: 4rem; */
}

.kb-login-form-input:focus {
  outline: transparent;
}

.kb-login-form-btn,
.kb-login-otp-btn {
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  padding: 15px 30px;
  border: transparent;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
}

.kb-login-form-btn:disabled,
.kb-login-otp-btn:disabled {
  /* background-color: grey !important; */
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.kb-login-otp-btn {
  margin-top: 2rem;
}

.kb-login-form-btn:focus {
  outline: transparent;
}

.kb-login-otp-container {
  font-size: 18px;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.kb-login-otp-input {
  /* -webkit-box-shadow: 7px 6px 5px 0px rgba(221,221,221,1);
  -moz-box-shadow: 7px 6px 5px 0px rgba(221,221,221,1);
  box-shadow: 7px 6px 5px 0px rgba(221,221,221,1); */
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  border: transparent;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 600;
}

.kb-otp-text {
  /* border: 1px solid black; */
  margin-top: 1rem;
}

.kb-otp-text-top {
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}

.kb-otp-text-bottom {
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  color: #f13636;
}

.kb-receipt-page {
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid black; */
  width: 100%;
  padding: 1.5rem 0;
  color: black;
}

.kb-receipt-header {
  /* border: 1px solid black; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-header-text {
  font-size: 1.3rem;
  font-weight: 500;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  /* border: 1px solid black; */
  margin-bottom: 0;
}

.kb-receipt-main {
  /* border: 1px solid black; */
}

.kb-receipt-top {
  /* border: 1px solid red; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
}

.kb-receipt-top-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.kb-receipt-top-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.kb-receipt-top-right-btn-left, .kb-receipt-top-right-btn-right {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: transparent;
}

.kb-receipt-top-right-btn-left {
  margin-right: 1rem;
}
.kb-receipt-top-left {
  /* border: 1px solid black; */
  width: 50%;
}

.kb-receipt-top-head,
.kb-receipt-top-sub {
  /* border: 1px solid red; */
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.kb-receipt-top-head {
  font-size: 15px;
}

.kb-receipt-top-sub {
  font-size: 10px;
  opacity: 0.5;
}

.kb-receipt-top-details {
  /* border: 1px solid black; */
  padding: 1rem 1rem 0 1rem;
}

.kb-receipt-top-details p {
  /* margin: 0; */
  font-weight: 500;
  font-size: 12px;
}

.kb-receipt-top-details-text {
  margin: 0;
}

.kb-receipt-top-details-heading {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.kb-receipt-line {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.kb-receipt-option {
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-footer {
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.kb-receipt-footer-btn {
  width: 100%;
  display: block;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 15px 30px;
  border: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  font-size: 18px;
}

.kb-receipt-footer-btn:focus {
  outline: transparent;
}

.kb-receipt-delivery-container {
    /* border: 1px solid black; */
}

.kb-receipt-delivery-container p {
    margin-bottom: 0;
}

.kb-receipt-delivery-top-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.kb-receipt-delivery-text-left {
    font-weight: 500;
    font-size: 13px;
    color: rgba(0,0,0,0.6);
    text-transform: uppercase;
}

.kb-receipt-delivery-text-right {
    font-weight: 600;
    font-size: 15px;
    color: #6bb9cd;
}

.kb-receipt-delivery-timeline {
    margin-top: 1.5rem;
}

.kb-receipt-delivery-status {
    font-weight: 500;
    font-size: 13px;
    color: white;
    background-color: #6bb9cd;
    padding: .25rem .75rem;
    border-radius: 20px;
    text-transform: capitalize;
}
.kb-timeline-with-tail {
    /* border: 1px solid black; */
}

.kb-timeline-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.kb-timeline-text {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
    margin-left: 1rem;
}

.kb-timeline-circle {
    width: 25px;
    height: 25px;
    background: #6bb9cd;
    border-radius: 50%;
}

.kb-timeline-line {
    background: #6bb9cd;
    width: 1px;
    height: 28px;
    margin-left: 12px;
}
.kb-receipt-dinein-container p {
  margin-bottom: 0;
}

.kb-receipt-dinein-head {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  padding-bottom: 1rem;
}

.kb-receipt-dinein-item {
  /* border: 1px solid black; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.kb-receipt-dinein-item-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.kb-receipt-dinein-item-left-img-div {
  width: 42px;
  height: 42px;
  /* border: 1px solid black; */
}

.kb-receipt-dinein-item-left-text {
  font-weight: 500;
  font-size: 13px;
  margin-left: 1rem;
}

.kb-receipt-dinein-item-right-text {
  font-weight: 500;
  font-size: 15px;
}

.kb-receipt-dinein-line {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.kb-receipt-dinein-order-details {
    /* border: 1px solid black; */
    font-size: 15px;
}

.kb-receipt-dinein-order-details div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.kb-receipt-dinein-delivery {
    margin-top: .25rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.kb-receipt-dinein-total {
    margin-top: 1rem;
}

.kb-receipt-takeaway-container p {
    margin-bottom: 0;
}

.kb-receipt-takeaway-top-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.kb-receipt-takeaway-text-left {
    font-weight: 500;
    font-size: 13px;
    color: rgba(0,0,0,0.6);
    text-transform: uppercase;
}

.kb-receipt-takeaway-text-right {
    font-weight: 600;
    font-size: 15px;
    color: #6bb9cd;
}

.kb-receipt-takeaway-timeline {
    margin-top: 1.5rem;
}

.kb-receipt-takeaway-status {
    font-weight: 500;
    font-size: 13px;
    color: white;
    background-color: #6bb9cd;
    padding: .25rem .75rem;
    border-radius: 20px;
    text-transform: capitalize;
}
:root {
    --primary-color: #6abacd !important;
    --primary-font: 'Lato' !important;
}

.kb-navbar {
    color: #6abacd !important;
    color: var(--primary-color) !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 5% !important;
    padding-bottom: 8% !important;
    position: fixed !important;
    width: 100% !important;
    box-shadow: none;
    border-bottom : none;
    border-width: 0;
    border-color: #ffffff;
}

.kb-navbar .center {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-size: 70px !important;
    line-height: 24px !important;
    text-align: center !important;
}

.kb-navbar input {
    border-radius: 20px !important;
    font-family: Lato !important;
    font-size: 12px !important;
    border: 1px !important;
    border-color : lightgray !important;
    border-style: solid !important;
}

.kb-navbar-heading {
    /* display: grid; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.kb-navbar-heading h6 {
    padding-right: 40%;
}

.kb-menu-item {
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
  grid-gap: 0.5rem;
  border-radius: 5px;
  border: transparent;
}

.kb-menu-item p {
  margin-bottom: 0;
}

.kb-menu-item-left-heading {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.kb-menu-item-left-text {
  font-weight: 400;
  font-size: 10px;
  margin-top: 0.25rem;
}

.kb-menu-item-left-price {
  font-weight: 700;
  font-size: 12px;
  margin-top: 0.25rem;
}

.kb-menu-item-right-img-container {
  width: 100%;
  height: 70%;
  border-radius: 5px;
}

.kb-menu-item-right-img {
  width: 100%;
    object-fit: contain;
    border-radius: 5px;
    min-width: 100%;
    max-height: 87px;
}

.kb-menu-item-right-btn {
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: white;
  margin: auto;
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 70px;
  color: white;
}

.kb-menu-item-cart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-weight: 500;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  margin: auto;
  margin-top: 0.5rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.kb-menu-item-qty-ctrl {
  color: white;
  border: transparent;
  padding: 0.2rem;
}

/* modal styles */
.kb-detail-modal .modal-content {
  /* background-image: url('../img/img.jpeg') !important; */
  /* background-color: aqua !important; */
  background-color: inherit;
  border: none;
  width: 100%;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}
.kb-detail-modal {
  position: relative;
}
.kb-detail-modal img {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.kb-detail-header {
  color: #ffffff !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  z-index: 10 !important;
  position: absolute !important;
  top: 0px;
}

.kb-detail-top-gradient {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.719) 20px,
    rgba(0, 0, 0, 0.082) 60px
  ) !important;
  padding: 7% !important;
  position: absolute !important;
  top: 0% !important;
  left: 0% !important;
  width: 100% !important;
  z-index: 1 !important;
}

.kb-detail-body {
  top: 10px;
  /* border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important; */
  border: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.kb-detail-body-heading {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  margin-bottom: 5% !important;
}

.kb-detail-body-heading h5 {
  font-size: 110% !important;
}

.kb-detail-body-heading .ant-rate {
  font-size: 110% !important;
}

.kb-details-footer {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background-color: #ffff !important;
  border: none !important;
}

.kb-details-footer .kb-detail-add-btn {
  background-color: #7ac1d1 !important;
  border: none !important;
  border-radius: 15px !important;
  width: 100% !important;
}

.kb-menu-item-eatarian {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  border-radius: 1px;
}

