:root {
    --primary-color: #6abacd !important;
    --primary-font: 'Lato' !important;
}

.kb-navbar {
    color: var(--primary-color) !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 5% !important;
    padding-bottom: 8% !important;
    position: fixed !important;
    width: 100% !important;
    box-shadow: none;
    border-bottom : none;
    border-width: 0;
    border-color: #ffffff;
}

.kb-navbar .center {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-size: 70px !important;
    line-height: 24px !important;
    text-align: center !important;
}

.kb-navbar input {
    border-radius: 20px !important;
    font-family: Lato !important;
    font-size: 12px !important;
    border: 1px !important;
    border-color : lightgray !important;
    border-style: solid !important;
}

.kb-navbar-heading {
    /* display: grid; */
    display: flex;
    justify-content: space-between;
}
.kb-navbar-heading h6 {
    padding-right: 40%;
}
