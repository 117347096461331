.kb-receipt-dinein-container p {
  margin-bottom: 0;
}

.kb-receipt-dinein-head {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  padding-bottom: 1rem;
}

.kb-receipt-dinein-item {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kb-receipt-dinein-item-left {
  display: flex;
  align-items: center;
}

.kb-receipt-dinein-item-left-img-div {
  width: 42px;
  height: 42px;
  /* border: 1px solid black; */
}

.kb-receipt-dinein-item-left-text {
  font-weight: 500;
  font-size: 13px;
  margin-left: 1rem;
}

.kb-receipt-dinein-item-right-text {
  font-weight: 500;
  font-size: 15px;
}

.kb-receipt-dinein-line {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.kb-receipt-dinein-order-details {
    /* border: 1px solid black; */
    font-size: 15px;
}

.kb-receipt-dinein-order-details div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kb-receipt-dinein-delivery {
    margin-top: .25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kb-receipt-dinein-total {
    margin-top: 1rem;
}
