.kb-menu-new-btn {
    background-color: #6abacd;
    font-family: "Lato";
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 50px;
    right: 33%;
    border-radius: 20px;
    margin: 5px;
}

.kb-menu-new-menu-label {
    display: block;
    font-family: "Lato";
    font-size: 15px;
    color: #ffffff;
}

.kb-menu-new-list-item {
    border: 0 !important;
    max-height: 60px;
    font-family: "Lato";
    font-size: 13px;
    background-color: white;
    margin: 0;
    padding: 5px;
}

.kb-menu-new-lable-light {
    display: block;
    font-family: "Lato" !important;
    font-size: 15px;
    color: #ffffff;
    background-color: #7ac1d1;
}

.kb-menu-new-menu-btn {
    /* background-color: #DAE5E0 !important; */
    font-family: "Lato" !important;
    display: block;
    border: none !important;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 20%;
    height: 70px;
    position: fixed;
    z-index: 120 !important;
    bottom: 25px !important;
    right: 38%;
    border-radius: 60px !important;
    margin: 5px;
    color: #7ac1d1;
}
