html, body, #root {
  min-height: 100vh;
}

.kb-btn {
  width: 253px;
  height: 43px;
  /* background-color: #7ac1d1 !important; */
  font-family: Lato;
  border: none !important;
  border-radius: 50px !important;
  color: #ffffff;
}
.kb-btn-2 {
  width: 253px;
  height: 43px;
  background-color: rgb(240, 80, 69) !important;
  font-family: Lato;
  border: none !important;
  border-radius: 50px !important;
  color: #ffffff;
}
.kb-lable {
  font-family: Lato;
  font-size: 20px;
  color: #636466;
}
.kb-category-label {
  background-color: white;
  border: none !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 10px;
}
.kb-navbar {
  background-color: white;
  text-align: center;
  font-family: Lato;
  box-shadow: 0px 1px 5px darkgrey;
  width: 100%;
  position: fixed !important;
  z-index: 100 !important;
  left: 0;
  bottom: 0;
}

/* ======== Qr Code Scanner ======= */
.kb-scanner-navbar {
  background-color: white;
  font-family: Lato;
  width: 100%;
  z-index: 100;
  left: "auto";
  top: 0;
  height: 190px;
}

.kb-scanner-navbar-brand {
  font-size: 50px !important;
  color: #5a5050;
  text-align: center;
  margin-top: 15px;
  width: 100%;
  font-family: Lato;
}

.kb-scanner-heading {
  font-family: Lato;
  text-align: center;
  color: #000000;
  font-size: 26px !important;
}

/* ========== Scanner Landing ========= */
.kb-scanner-card {
  margin-top: 20px !important;
  /* box-shadow: 1px 1px 5px darkgrey; */
  /* margin-left: 2em;
    margin-right: 2em; */
}

.kb-scanner-btn1 {
  background-color: #6abacd !important;
  font-family: Lato !important;
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
  border: none !important;
  font-size: 15px !important;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px !important;
  padding: 10px 16px !important;
}

.kb-scanner-btn2 {
  background-color: #f15b4d !important;
  font-family: Lato !important;
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
  border: none !important;
  font-size: 15px !important;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
  border-radius: 6px !important;
  padding: 10px 16px !important;
}

/* .kb-label {
    display: block;
    font-family: Lato;
    font-size: 15px;
    color: #544f4f;
} */

/* ====== Menu Item =========== */
.kb-menu-label {
  display: block;
  font-family: Lato;
  font-size: 15px;
  color: "#544f4f";
}

.kb-menu-label2 {
  display: block;
  font-family: Lato;
  font-size: 15px;
  color: "#544f4f";
}

.kb-menu-add-btn {
  background-color: "#6abacd" !important;
  font-family: Lato !important;
  border: none !important;
  font-size: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 1px 0px !important;
  border-radius: 6px !important;
  height: 40px !important;
  margin-top: -8px !important;
}

.card-cart-btn {
  border: none;
  color: #7ac1d1;
  font-family: Lato;
  padding: 1%;
  border-width: 1px;
  border-style: solid;
  border-color: gray !important;
  padding: 4%;
}
.card-cart-btn:focus {
  color: #6abacd !important;
  background-color: #e4faff !important;
  border: none !important;
}

.card-btn-l {
  background-color: #ffffff;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.card-btn-r {
  background-color: #ffffff;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
.footer-svg {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

/* _____ Filter ____ */
.kb-filter {
  display: flex;
  margin-top: 160px;
  flex-direction: row;
  padding-left: 13px;
  color: #a9a6a6;
}
.kb-filter h6 {
  margin-bottom: 0px;
  margin-top: 5px;
}
.special_price {
  text-decoration: line-through !important;
  color: gray;
}
.pos_wrapper {
  display: flex;
  height: 40px;
  /* align-content: space-around; */
  overflow-x: scroll;
  width: 100%;
}
.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* border: solid; */
  border-width: 1.02px;
  margin: auto;
}

.scrollmenu span {
  display: inline-block;
  /* color: white; */
  text-align: center;
  padding: 14px;
  text-decoration: none;
  /* border: solid;
  border-left-width: 10px; */
}

.scrollmenu span {
  border-right: thin;
  border-style: solid;
  /* border: 1px; */
  border-width: thin;
}
.scrollmenu .active {
  background-color: rgb(238, 255, 255);
  /* border: solid; */
}
.text-shadow {
  text-shadow: 1px 1px 11px rgba(140, 150, 150, 1);
}

@import "./cart.css";
@import "./mainMenu.css";
@import "./MenuNew.css";
@import "./MenuItem.css";

/* *{
    font-family: 'Montserrat' !important;
} */
