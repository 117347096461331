input[type='radio'] {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 1px gray;
    margin-right:15px
  }
  
  input[type='radio']:checked {
    box-shadow: 0 0 0 1px #3d9dac;
  }
  
  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  
  
  input[type='radio']:checked:before {
    background: #3d9dac;
  }
  
  input[type="radio"]:checked + p {
    font-weight:bold;
  } 

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 12px;
    width: 12px;
    border: 2px solid gray;
    margin-right:15px;
  }
  
  input[type="checkbox"]:checked {
    background: #3d9dac;
  } 

  input[type="checkbox"]:checked + p {
    font-weight:bold;
  } 

  #position-relative.form-group .label{
      display:none !important;
  }

  .form-control:focus{
    -webkit-box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.Select-clear {
  display:none !important;
}
