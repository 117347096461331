.kb-choice-field-container {
  font-family: "Poppins", sans-serif;
}

.kb-choice-title-new {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}

.kb-choice-radio-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kb-choice-radio-container p {
  margin-bottom: 0;
}

.kb-choice-radio-div {
  display: flex;
  align-items: center;
  width: 100%;
}

.kb-choice-radio-price-text {
  margin-left: auto;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.kb-choice-radio-input {
  margin-bottom: 0;
}

.kb-choice-radio-label {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.kb-choice-custom-bold {
  font-weight: 700;
}

.kb-choice-check-container {
  /* border: 1px solid black; */
}

.kb-choice-check-label {
  margin-bottom: 1rem;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.kb-choice-check-label p {
  margin-bottom: 0;
}

.kb-choice-check-text {
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.03rem;
}
