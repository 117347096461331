@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.kb-login-page {
  font-family: "Montserrat", sans-serif;
}

.kb-login-modal {
  font-family: "Montserrat", sans-serif;
}

.kb-login-modal-body {
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  /* margin-top: 10rem; */
  padding-bottom: 0;
  position: relative;
  height: 100%;
}

.kb-login-modal-close {
  /* border: 1px solid black; */
  position: absolute;
  right: -8px;
  top: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kb-login-modal-close-text {
  margin-bottom: 0;
  font-size: 60px;
}

.modal-content {
  border: transparent !important;
}

.kb-login-page-numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.kb-login-page-number {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
  border-radius: 50%;
}


.kb-login-page-title {
  font-weight: 600;
  font-size: 28px;
}

.kb-login-page-sub {
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0;
}

.kb-login-page-sub-span {
  font-weight: 600;
}

.kb-login-form-container {
  margin-top: 3rem;
}

.kb-login-form-otp-container {
  margin-top: 1.5rem;
}

.kb-login-form-label {
  font-weight: 500;
  font-size: 12px;
  width: 100%;
}

.kb-login-form-grid {
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: 1fr 9fr;
  gap: 0.25rem;
  align-items: center;
  /* display: flex; */
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 4px solid #ddd;
  position: relative;
}

.kb-login-icon {
  position: absolute;
  right: 5px;
  bottom: 2.5px;
}

.kb-login-form-text {
  margin-bottom: 0;
}

.kb-login-form-input {
  width: 100%;
  border: transparent;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: 500;
  /* padding-left: 4.8rem;
  padding-right: 4rem; */
}

.kb-login-form-input:focus {
  outline: transparent;
}

.kb-login-form-btn,
.kb-login-otp-btn {
  width: 100%;
  display: block;
  font-family: "Montserrat", sans-serif;
  padding: 15px 30px;
  border: transparent;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
}

.kb-login-form-btn:disabled,
.kb-login-otp-btn:disabled {
  /* background-color: grey !important; */
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.kb-login-otp-btn {
  margin-top: 2rem;
}

.kb-login-form-btn:focus {
  outline: transparent;
}

.kb-login-otp-container {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.kb-login-otp-input {
  /* -webkit-box-shadow: 7px 6px 5px 0px rgba(221,221,221,1);
  -moz-box-shadow: 7px 6px 5px 0px rgba(221,221,221,1);
  box-shadow: 7px 6px 5px 0px rgba(221,221,221,1); */
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  border: transparent;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 600;
}

.kb-otp-text {
  /* border: 1px solid black; */
  margin-top: 1rem;
}

.kb-otp-text-top {
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}

.kb-otp-text-bottom {
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  color: #f13636;
}
