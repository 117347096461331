.kb-menu-item {
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
  grid-gap: 0.5rem;
  border-radius: 5px;
  border: transparent;
}

.kb-menu-item p {
  margin-bottom: 0;
}

.kb-menu-item-left-heading {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.kb-menu-item-left-text {
  font-weight: 400;
  font-size: 10px;
  margin-top: 0.25rem;
}

.kb-menu-item-left-price {
  font-weight: 700;
  font-size: 12px;
  margin-top: 0.25rem;
}

.kb-menu-item-right-img-container {
  width: 100%;
  height: 70%;
  border-radius: 5px;
}

.kb-menu-item-right-img {
  width: 100%;
    object-fit: contain;
    border-radius: 5px;
    min-width: 100%;
    max-height: 87px;
}

.kb-menu-item-right-btn {
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: white;
  margin: auto;
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 70px;
  color: white;
}

.kb-menu-item-cart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-weight: 500;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  margin: auto;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
}

.kb-menu-item-qty-ctrl {
  color: white;
  border: transparent;
  padding: 0.2rem;
}

/* modal styles */
.kb-detail-modal .modal-content {
  /* background-image: url('../img/img.jpeg') !important; */
  /* background-color: aqua !important; */
  background-color: inherit;
  border: none;
  width: 100%;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}
.kb-detail-modal {
  position: relative;
}
.kb-detail-modal img {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.kb-detail-header {
  color: #ffffff !important;
  display: flex !important;
  justify-content: space-between !important;
  z-index: 10 !important;
  position: absolute !important;
  top: 0px;
}

.kb-detail-top-gradient {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.719) 20px,
    rgba(0, 0, 0, 0.082) 60px
  ) !important;
  padding: 7% !important;
  position: absolute !important;
  top: 0% !important;
  left: 0% !important;
  width: 100% !important;
  z-index: 1 !important;
}

.kb-detail-body {
  top: 10px;
  /* border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important; */
  border: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.kb-detail-body-heading {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-bottom: 5% !important;
}

.kb-detail-body-heading h5 {
  font-size: 110% !important;
}

.kb-detail-body-heading .ant-rate {
  font-size: 110% !important;
}

.kb-details-footer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  background-color: #ffff !important;
  border: none !important;
}

.kb-details-footer .kb-detail-add-btn {
  background-color: #7ac1d1 !important;
  border: none !important;
  border-radius: 15px !important;
  width: 100% !important;
}

.kb-menu-item-eatarian {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  border-radius: 1px;
}
