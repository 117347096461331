.kb-menu-navbar {
    background-color: white;
    font-family: 'Lato' !important;
    box-shadow: 0px 1px 5px darkgrey;
    width: 100% !important;
    position: fixed !important;
    z-index: 99 !important;
    left: auto !important;
    
    top: 0 !important;
    height: 135px
}

.kb-menu-navbar-brand {
    font-size: 15px;
    color: #5a5050 !important;
    text-align: center;
    width: 100%;
    font-family: 'Lato' !important;
}

.kb-menu-btn-danger {
    background-color: #ffffff;
    font-family: 'Lato';
    border: none;
    border-radius: 6px;
    color: #f15b4d;
    padding: 5px;
    width: 40px;
    height: inherit;
}

.kb-menu-view-btn {
    background-color: #f15b4d;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    border-radius: 6px;
    width: 50%;
    height: 40px;
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
}

.best-seller-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-column-gap: 5px;
}

.kb-menu-cancel-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 50%;
    height: 40px;
    position: fixed;
    z-index: 120;
    right: 0;
    bottom: 0
}

.kb-menu-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 48px;
    left: 0;
    border-radius: 20px;
    margin: 5px;
}

.kb-menu-cart-alt-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    border: none;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    width: 30%;
    height: 40px;
    position: fixed;
    z-index: 120;
    bottom: 48px;
    left: 0;
    border-radius: 20px;
    margin: 5px;
    margin-left: "35%"
}

.kb-menu-btn-green {
    background-color: #5cb85c;
    margin-top: 20px;
    font-family: 'Lato';
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-size: 15px;
    width: 130px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;
    border-radius: 6px;
    padding: 10px 16px;
}

.kb-menu-card {
    margin-top: 5px;
    box-shadow: "1px 1px 5px darkgrey"
}

.kb-menu-card-title {
    font-family: 'Lato';
    font-size: 15px;
    color: #6abacd;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-bottom-color: #b9a6a6;
}

.kb-menu-label {
    display: block;
    font-family: 'Lato';
    font-size: "15px"
}

.kb-menu-label-light {
    display: block;
    font-family: 'Lato';
    font-size: 15px;
    color: #ffffff;
}

.kb-menu-table {
    font-family: 'Lato';
    font-size: 15px;
    color: #544f4f;
}

.kb-menu-thead {
    color: rgb(106, 186, 205);
}

.kb-menu-tip {
    border: none;
    border-bottom: 1px solid;
    border-bottom-color: #b9a6a6;
    font-family: 'Lato';
    font-size: 15px;
    color: #544f4f;
    width: 60px;
    text-align: right;
}

.kb-menu-tip-btn {
    background-color: #6abacd;
    font-family: 'Lato';
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: none;
    font-size: 12px;
    max-width: 60px;
    box-shadow: 1px 3px 5px lightgrey;
}

.kb-menu-bottom {
    width: 40%;
}

.kb-menu-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.kb-menu-list {
    border: 0;
    max-height: 60px;
    font-family: 'Lato';
    font-size: 15px;
    background-color: white;
    margin: 0;
    padding: 5px;
}